import React from "react";
import { Card } from "../utility/Card";
import { WhyLove } from "../../data";
import { Button, SectionTitle, SpecialTitle } from "../utility";
import { Product_Routes } from "../../data/Routes";

export function LoveSection() {
  return (
    <section className="why-section">
      <div className="why-wrapper">
        <div className="title-wraper">
          <SpecialTitle>Features You’ll Love</SpecialTitle>
          <SectionTitle>Why You’ll Love Digital Comparison</SectionTitle>
        </div>
        <div className="card-wraper">
          {WhyLove.map((item, id) => {
            return (
              <Card
                key={id}
                icon={<item.src />}
                title={item.title}
                paragraph={item.paragraph}
              />
            );
          })}
        </div>
      </div>
      <Button to={Product_Routes.Aboutus}>Read more</Button>
    </section>
  );
}
