import React from "react";
import { Button } from "../utility/Button";
import step1 from "../../svgs/Patterns_Svgs/step1.svg";
import step2 from "../../svgs/Patterns_Svgs/step2.svg";
import step3 from "../../svgs/Patterns_Svgs/step3.svg";
import MainBackground from "../../images/ProductHeaderImages/MainBackground.jpeg";

export function MainHeader() {
  return (
    <header className="main-header">
      <img
        className="main-header-image"
        src={MainBackground}
        alt="Compare and save money"
      />
      <div className="header-wrapper">
        <div className="title-container">
          <div className="header-title-wrapper">
            <h1 className="main-header-title">Compare and Save</h1>
            <p className="main-header-paragraph">
              Answer the questions and get free quotes from leading industry
              suppliers.
            </p>
          </div>
        </div>
        <div className="button-container">
          <Button href="#productandservices">See all product & services</Button>
        </div>
        <div className="steps-container">
          <p className="steps-paragraph">
            Find the Best Offers in
            <span className="steps-oblique">#3 Simple Steps</span>
          </p>
          <div className="steps-card-container">
            <div className="step-card">
              <div className="steps-content">
                <h3 className="special-step-subtitle"> Step-1 </h3>
                <h2 className="special-step-title"> Connect With Providers </h2>
                <p className="special-step-paragraph">
                  Fill out our simple questionnaire and we’ll connect you with
                  the ideal providers to suit your needs.
                </p>
              </div>
              <div className="steps-icon-cover">
                <img src={step1} alt={step1} />
              </div>
            </div>
            <div className="step-card">
              <div className="steps-content">
                <h3 className="special-step-subtitle"> Step-2 </h3>
                <h2 className="special-step-title"> Receive Quotes </h2>
                <p className="special-step-paragraph">
                  Receive obligation-free custom quotes that you can compare.
                </p>
              </div>
              <div className="steps-icon-cover">
                <img src={step2} alt={step2} />
              </div>
            </div>
            <div className="step-card">
              <div className="steps-content">
                <h3 className="special-step-subtitle"> Step-3 </h3>
                <h2 className="special-step-title"> Make a Choice </h2>
                <p className="special-step-paragraph">
                  Pick an offer that suits you or keep shopping. It’s your
                  choice!
                </p>
              </div>
              <div className="steps-icon-cover">
                <img src={step3} alt={step3} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
