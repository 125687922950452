import React from "react";

export default function Phone() {
  return (
    <svg
      width="43"
      height="52"
      viewBox="0 0 43 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Phone">
        <g id="Group">
          <path
            id="Vector"
            d="M30.3097 14.5988C30.3097 15.0217 29.9669 15.3645 29.544 15.3645C29.1211 15.3645 28.7783 15.0217 28.7783 14.5988C28.7783 14.1759 29.1211 13.8331 29.544 13.8331C29.9669 13.8331 30.3097 14.1759 30.3097 14.5988Z"
            fill="white"
          />
          <path
            id="Vector_2"
            d="M32.4997 9.66826C32.6223 9.74205 32.7576 9.77696 32.8913 9.77696C33.1495 9.77696 33.4018 9.64525 33.5447 9.40761C33.7613 9.04698 33.6446 8.57884 33.284 8.36223C30.9774 6.97606 28.1111 6.97606 25.8041 8.36223C25.4435 8.57884 25.3268 9.04698 25.5435 9.40761C25.7601 9.76784 26.2282 9.88487 26.5888 9.66786C28.4118 8.57289 30.6767 8.57289 32.4997 9.66826Z"
            fill="white"
          />
          <path
            id="Vector_3"
            d="M31.6064 11.1576C30.3341 10.3939 28.7536 10.3939 27.4816 11.1576C27.121 11.3742 27.004 11.8424 27.2206 12.203C27.3634 12.441 27.6153 12.5727 27.8744 12.5727C28.0077 12.5727 28.143 12.5378 28.2656 12.464C29.0543 11.9907 30.0338 11.9907 30.8221 12.464C31.1831 12.6807 31.6509 12.5636 31.8675 12.203C32.0837 11.8424 31.9671 11.3742 31.6064 11.1576Z"
            fill="white"
          />
          <path
            id="Vector_4"
            d="M38.4996 0H20.5885C19.3943 0 18.3513 0.656982 17.8003 1.62817H15.235C14.684 0.656982 13.6414 0 12.4472 0H4.30554C2.53891 0 1.10156 1.43735 1.10156 3.20398V37.398C1.10156 39.1646 2.53891 40.602 4.30554 40.602H7.61465V43.097C7.61465 48.0061 11.6085 52 16.5176 52C21.4268 52 25.421 48.0061 25.421 43.097V40.602H38.4996C40.2662 40.602 41.7035 39.1646 41.7035 37.398V3.20398C41.7035 1.43735 40.2662 0 38.4996 0V0ZM2.625 37.398V3.20398C2.625 2.27722 3.37878 1.52344 4.30554 1.52344H12.4472C13.374 1.52344 14.1277 2.27722 14.1277 3.20398V33.3272C14.1277 33.7477 14.4689 34.0889 14.8895 34.0889C15.31 34.0889 15.6512 33.7477 15.6512 33.3272V3.20398C15.6512 3.18652 15.65 3.16907 15.65 3.15161H17.3857C17.3853 3.16907 17.3845 3.18652 17.3845 3.20398V37.398C17.3845 37.4155 17.3853 37.4329 17.3857 37.4504H15.65C15.65 37.4329 15.6512 37.4155 15.6512 37.398V36.5839C15.6512 36.163 15.31 35.8222 14.8895 35.8222C14.4689 35.8222 14.1277 36.163 14.1277 36.5839V37.398C14.1277 38.3248 13.374 39.0786 12.4472 39.0786H4.30554C3.37878 39.0786 2.625 38.3248 2.625 37.398ZM23.8972 43.097C23.8972 47.1659 20.5869 50.4766 16.5176 50.4766C12.4484 50.4766 9.13809 47.1659 9.13809 43.097V40.602H12.4468C13.641 40.602 14.684 39.945 15.235 38.9738H17.8003C18.3513 39.945 19.3943 40.602 20.5885 40.602H23.8976V43.097H23.8972ZM38.4996 39.0786H20.5885C19.6617 39.0786 18.9079 38.3248 18.9079 37.398V19.4345H19.774C20.1945 19.4345 20.5357 19.0934 20.5357 18.6728C20.5357 18.2523 20.1945 17.9111 19.774 17.9111H18.9079V3.20398C18.9079 2.27722 19.6617 1.52344 20.5885 1.52344H38.4996C39.4263 1.52344 40.1801 2.27722 40.1801 3.20398V17.9111H23.0307C22.6102 17.9111 22.269 18.2523 22.269 18.6728C22.269 19.0934 22.6102 19.4345 23.0307 19.4345H40.1801V37.398C40.1801 38.3248 39.4263 39.0786 38.4996 39.0786Z"
            fill="white"
          />
          <path
            id="Vector_5"
            d="M26.2876 15.3637C26.7082 15.3637 27.0493 15.0225 27.0493 14.602C27.0493 14.1815 26.7082 13.8403 26.2876 13.8403H23.0309C23.0019 13.8403 22.9785 13.8169 22.9785 13.7879V5.64664C22.9785 5.61768 23.0019 5.59427 23.0309 5.59427H36.0571C36.086 5.59427 36.1098 5.61768 36.1098 5.64664V13.7879C36.1098 13.8169 36.086 13.8403 36.0571 13.8403H32.8007C32.3798 13.8403 32.039 14.1815 32.039 14.602C32.039 15.0229 32.3798 15.3637 32.8007 15.3637H36.0571C36.9263 15.3637 37.6333 14.6571 37.6333 13.7879V5.64664C37.6333 4.7774 36.9263 4.07083 36.0571 4.07083H23.0309C22.162 4.07083 21.4551 4.7774 21.4551 5.64664V13.7879C21.4551 14.6571 22.162 15.3637 23.0309 15.3637H26.2876Z"
            fill="white"
          />
          <path
            id="Vector_6"
            d="M35.2429 21.9819C33.925 21.9819 32.853 23.0539 32.853 24.3718C32.853 25.6894 33.925 26.7617 35.2429 26.7617C36.5609 26.7617 37.6328 25.6894 37.6328 24.3718C37.6328 23.0539 36.5609 21.9819 35.2429 21.9819ZM35.2429 25.2383C34.7653 25.2383 34.3765 24.8495 34.3765 24.3718C34.3765 23.8942 34.7653 23.5054 35.2429 23.5054C35.721 23.5054 36.1094 23.8942 36.1094 24.3718C36.1094 24.8495 35.721 25.2383 35.2429 25.2383Z"
            fill="white"
          />
          <path
            id="Vector_7"
            d="M35.2429 28.4946C33.925 28.4946 32.853 29.567 32.853 30.8849C32.853 32.2025 33.925 33.2748 35.2429 33.2748C36.5609 33.2748 37.6328 32.2025 37.6328 30.8849C37.6328 29.567 36.5609 28.4946 35.2429 28.4946ZM35.2429 31.7514C34.7653 31.7514 34.3765 31.3626 34.3765 30.8849C34.3765 30.4073 34.7653 30.0185 35.2429 30.0185C35.721 30.0185 36.1094 30.4073 36.1094 30.8849C36.1094 31.3626 35.721 31.7514 35.2429 31.7514Z"
            fill="white"
          />
        </g>
      </g>
    </svg>
  );
}
