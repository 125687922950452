import React from "react";

export function NoObligation() {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="NoObligation" clip-path="url(#clip0)">
        <path
          id="Vector"
          d="M39.3564 29.4104L39.4674 29.7164C41.4345 35.161 41.1295 41.1669 38.622 46.3839L36.3962 51.0142L36.5153 49.0842C36.7286 45.6127 35.0653 42.2925 32.1571 40.3847"
          fill="white"
        />
        <path
          id="Vector_2"
          d="M26.8144 21.1827L26.4895 21.2028C20.7119 21.5667 15.3242 24.2383 11.5375 28.616L8.17659 32.5017L9.8995 31.6239C12.999 30.0457 16.7068 30.2491 19.615 32.157"
          fill="white"
        />
        <path
          id="Vector_3"
          d="M7.43916 33.0797C7.50373 33.1624 7.57965 33.2313 7.66274 33.2858C7.93788 33.4663 8.29691 33.4933 8.60232 33.3371L10.3252 32.4592C12.8369 31.1806 15.8043 31.2121 18.2834 32.4785L17.8031 33.2107C17.5192 33.6434 17.64 34.2249 18.0727 34.5088L18.8566 35.023L17.3139 37.3747C17.03 37.8074 17.1508 38.3889 17.5835 38.6728L21.5029 41.244L19.446 44.3795L21.0137 45.408L23.0707 42.2725L26.9901 44.8436C27.4228 45.1275 28.0043 45.0067 28.2882 44.574L29.8309 42.2224L30.6148 42.7366C31.0475 43.0205 31.629 42.8997 31.9129 42.467L32.3927 41.7356C34.5421 43.5045 35.7523 46.2146 35.5797 49.0277L35.4606 50.9577C35.4403 51.2998 35.6072 51.6179 35.8824 51.7984C35.9655 51.8529 36.0589 51.8951 36.1605 51.9214C36.5942 52.0344 37.0469 51.8246 37.2418 51.4199L39.4676 46.7895C42.0455 41.4259 42.3797 35.1465 40.3952 29.5369L42.6173 26.1498C46.4633 20.2871 47.7954 13.2777 46.3701 6.41248L46.3453 6.29305C46.2398 5.78549 45.7444 5.46049 45.2369 5.56592L45.1174 5.59072C38.253 7.01658 32.3534 11.0308 28.5075 16.8935L26.2854 20.2806C20.3496 20.6943 14.7227 23.5016 10.8289 28.0031L7.46803 31.8888C7.1749 32.228 7.16274 32.7268 7.43916 33.0797ZM27.2347 42.7616L19.3959 37.6193L20.4244 36.0515L28.2631 41.1939L27.2347 42.7616ZM37.7772 45.9788L37.3465 46.8747C36.9385 44.239 35.5417 41.8303 33.4278 40.1577L39.0562 31.5781C40.3599 36.3453 39.9262 41.5066 37.7772 45.9788ZM44.6896 7.60855C45.1815 10.4496 45.1621 13.3061 44.6639 16.0738L36.9348 11.0034C39.2752 9.44412 41.8877 8.28873 44.6896 7.60855ZM30.0752 17.9219C31.5274 15.7083 33.2979 13.7819 35.313 12.1819L44.2296 18.0313C43.5636 20.5164 42.5017 22.9076 41.0495 25.1213L30.8594 40.6546L19.8851 33.4553L30.0752 17.9219ZM12.2463 29.2301C15.4919 25.4773 20.0543 23.0245 24.9458 22.3226L19.318 30.9014C16.9426 29.629 14.1762 29.3065 11.5961 29.9819L12.2463 29.2301Z"
          fill="#242424"
        />
        <path
          id="Vector_4"
          d="M36.6852 19.8093C34.0914 18.1077 30.5981 18.8333 28.8965 21.4271C27.1949 24.021 27.9205 27.5142 30.5143 29.2158C33.1082 30.9175 36.6014 30.1919 38.303 27.598C40.0047 25.0042 39.2791 21.5109 36.6852 19.8093ZM31.5428 27.6481C29.8136 26.5137 29.3299 24.1849 30.4643 22.4556C31.5987 20.7264 33.9275 20.2427 35.6567 21.3771C37.386 22.5115 37.8697 24.8403 36.7353 26.5695C35.6009 28.2988 33.2721 28.7825 31.5428 27.6481Z"
          fill="#242424"
        />
        <path
          id="Vector_5"
          d="M18.4892 42.1317L17.7551 40.4067L16.1016 41.1103C14.9779 41.5875 14.0295 42.3803 13.3595 43.4017C12.456 44.7789 12.1421 46.4264 12.4778 48.0392L13.655 53.7068C13.7076 53.9599 13.8579 54.1684 14.0586 54.3C14.2592 54.4317 14.5103 54.4865 14.7634 54.4339L20.431 53.2567C22.0441 52.9223 23.4303 51.9783 24.3338 50.601C25.0038 49.5796 25.3534 48.3939 25.3441 47.1724L25.331 45.3755L23.4563 45.3891L23.4694 47.186C23.4754 48.0365 23.2324 48.8616 22.766 49.5725C22.1371 50.5312 21.1726 51.1879 20.0497 51.4211L15.2999 52.4078L14.3133 47.6579C14.0801 46.5351 14.2983 45.3888 14.9272 44.4302C15.3937 43.7192 16.0537 43.1677 16.8362 42.8345L18.4892 42.1317Z"
          fill="#242424"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="60" height="60" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
