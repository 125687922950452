import React from "react";
import { Button } from "../utility";

export function SpecialHeader({ src, title, to }) {
  return (
    <header className="product-header special-header">
      <img className="product-header-image" src={src} alt={src} />
      <div className="blur-bg">
        <div className="content-wrapper">
          <div className="product-header-content">
            <div className="product-header-title">{title}</div>
          </div>
          {to && (
            <div className="button-arrow-wrapper">
              <Button to={to}>Contact us</Button>
            </div>
          )}
        </div>
      </div>
    </header>
  );
}
