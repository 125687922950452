import React from "react";

export function ProtectPrivacy() {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="ProtectPrivacy">
        <path
          id="Vector"
          d="M30 17.8125C27.5896 17.8125 25.2332 18.5272 23.2291 19.8664C21.2248 21.2057 19.6627 23.109 18.7403 25.3361C17.8178 27.563 17.5764 30.0135 18.0467 32.3776C18.517 34.7417 19.6777 36.9133 21.3822 38.6178C23.0867 40.3223 25.2583 41.4831 27.6224 41.9533C29.9866 42.4236 32.4371 42.1822 34.664 41.2597C36.891 40.3373 38.7944 38.7752 40.1336 36.771C41.4727 34.7668 42.1875 32.4104 42.1875 30C42.1875 26.7676 40.9035 23.6678 38.6179 21.3821C36.3323 19.0965 33.2323 17.8125 30 17.8125ZM30 38.4375C28.3313 38.4375 26.6999 37.9426 25.3124 37.0155C23.9249 36.0883 22.8434 34.7706 22.2048 33.2289C21.5662 31.6871 21.3991 29.9906 21.7246 28.3539C22.0503 26.7172 22.8538 25.2138 24.0338 24.0337C25.2139 22.8538 26.7173 22.0502 28.3539 21.7246C29.9907 21.399 31.6872 21.5661 33.2289 22.2048C34.7706 22.8434 36.0884 23.9249 37.0156 25.3124C37.9427 26.6999 38.4375 28.3312 38.4375 30C38.4375 32.2378 37.5486 34.3839 35.9663 35.9662C34.3839 37.5485 32.2379 38.4375 30 38.4375Z"
          fill="white"
        />
        <path
          id="Vector_2"
          d="M24.375 30.9375L28.125 34.6875L34.6875 28.125L32.8125 26.25L28.125 30.9375L26.25 29.0625L24.375 30.9375Z"
          fill="white"
        />
        <path
          id="Vector_3"
          d="M55.3125 39.375V10.3125C55.3125 10.0638 55.2137 9.82539 55.0379 9.64957C54.8621 9.47375 54.6237 9.37498 54.375 9.37498C47.2092 9.37886 40.1939 7.31861 34.1681 3.4406L30.5072 1.08654C30.3559 0.989237 30.1799 0.9375 30 0.9375C29.8201 0.9375 29.6441 0.989237 29.4928 1.08654L25.8319 3.4406C19.8061 7.31861 12.7908 9.37886 5.625 9.37498C5.37636 9.37498 5.1379 9.47375 4.96208 9.64957C4.78628 9.82539 4.6875 10.0638 4.6875 10.3125V39.375C4.68742 39.5187 4.72034 39.6604 4.78373 39.7893C4.84713 39.9182 4.9393 40.0308 5.05313 40.1184L29.4281 58.8684C29.5921 58.9946 29.7932 59.0631 30 59.0631C30.2068 59.0631 30.4079 58.9946 30.5719 58.8684L54.9469 40.1184C55.0607 40.0308 55.1529 39.9182 55.2163 39.7893C55.2797 39.6604 55.3126 39.5187 55.3125 39.375ZM53.4375 38.9137L30 56.9419L6.5625 38.9137V11.2387C13.7672 11.0711 20.7858 8.91829 26.8453 5.01748L30 2.98873L33.1547 5.01748C39.2142 8.91829 46.2328 11.0711 53.4375 11.2387V38.9137Z"
          fill="#242424"
        />
        <path
          id="Vector_4"
          d="M30.5513 5.80395C30.3911 5.68749 30.198 5.62476 30 5.62476C29.802 5.62476 29.6089 5.68749 29.4488 5.80395L27.48 7.23927C22.2247 11.0772 15.8824 13.139 9.375 13.1249C9.12636 13.1249 8.8879 13.2237 8.71208 13.3995C8.53628 13.5753 8.4375 13.8138 8.4375 14.0624V37.4999C8.43748 37.643 8.47025 37.7843 8.53331 37.9129C8.59636 38.0415 8.68801 38.1538 8.80125 38.2415L29.4263 54.179C29.5903 54.3056 29.7918 54.3743 29.9991 54.3743C30.2064 54.3743 30.4078 54.3056 30.5719 54.179L51.1969 38.2415C51.3104 38.1541 51.4024 38.0417 51.4658 37.9131C51.5292 37.7846 51.5623 37.6433 51.5625 37.4999V14.0624C51.5625 13.8138 51.4637 13.5753 51.2879 13.3995C51.1121 13.2237 50.8737 13.1249 50.625 13.1249C44.1189 13.1382 37.778 11.0764 32.5238 7.23927L30.5513 5.80395ZM30 41.2499C27.775 41.2499 25.5998 40.5901 23.7498 39.354C21.8998 38.1177 20.4579 36.3608 19.6064 34.305C18.7549 32.2495 18.532 29.9874 18.9662 27.8051C19.4003 25.6229 20.4717 23.6183 22.0451 22.0449C23.6184 20.4717 25.6229 19.4001 27.8052 18.966C29.9876 18.532 32.2495 18.7547 34.3052 19.6062C36.3608 20.4577 38.1178 21.8997 39.354 23.7497C40.5902 25.5998 41.25 27.7749 41.25 29.9999C41.2467 32.9826 40.0604 35.8422 37.9514 37.9513C35.8423 40.0604 32.9827 41.2466 30 41.2499ZM10.3125 14.9868C16.8926 14.8114 23.2635 12.6379 28.5787 8.7552L29.0625 8.40363V16.9227C25.7517 17.154 22.6519 18.6324 20.3884 21.0595C18.1249 23.4866 16.8661 26.682 16.8661 30.0009C16.8661 33.3196 18.1249 36.515 20.3884 38.9422C22.6519 41.3693 25.7517 42.8477 29.0625 43.079V51.5306L10.3125 37.0387V14.9868ZM49.6875 37.0396L30.9375 51.5287V43.0771C34.2483 42.8458 37.3481 41.3675 39.6116 38.9403C41.8751 36.5131 43.1339 33.3177 43.1339 29.999C43.1339 26.6801 41.8751 23.4848 39.6116 21.0576C37.3481 18.6305 34.2483 17.1521 30.9375 16.9209V8.40363L31.4212 8.7552C36.7365 12.6379 43.1074 14.8114 49.6875 14.9868V37.0396Z"
          fill="#242424"
        />
        <path
          id="Vector_5"
          d="M30 20.625C28.1458 20.625 26.3332 21.1748 24.7915 22.2049C23.2498 23.2351 22.0483 24.6993 21.3387 26.4123C20.6291 28.1253 20.4434 30.0104 20.8051 31.8289C21.1669 33.6476 22.0597 35.318 23.3708 36.6292C24.682 37.9403 26.3524 38.8331 28.1711 39.1949C29.9896 39.5566 31.8747 39.3709 33.5877 38.6613C35.3008 37.9517 36.7649 36.7502 37.7951 35.2085C38.8252 33.6668 39.375 31.8543 39.375 30C39.3723 27.5144 38.3837 25.1314 36.6262 23.3739C34.8686 21.6163 32.4856 20.6277 30 20.625ZM30 37.5C28.5167 37.5 27.0666 37.0601 25.8332 36.236C24.5999 35.4119 23.6386 34.2405 23.071 32.8701C22.5033 31.4996 22.3547 29.9917 22.6442 28.5368C22.9335 27.0819 23.6479 25.7456 24.6967 24.6967C25.7456 23.6478 27.0819 22.9335 28.5368 22.6441C29.9917 22.3547 31.4997 22.5033 32.8702 23.0709C34.2406 23.6386 35.412 24.5999 36.236 25.8333C37.0601 27.0666 37.5 28.5166 37.5 30C37.4978 31.9884 36.7069 33.8948 35.3009 35.3009C33.8949 36.7069 31.9885 37.4978 30 37.5Z"
          fill="#242424"
        />
        <path
          id="Vector_6"
          d="M35.3506 27.4623L33.4756 25.5873C33.2998 25.4115 33.0613 25.3127 32.8128 25.3127C32.5642 25.3127 32.3257 25.4115 32.1499 25.5873L28.1253 29.612L26.9131 28.3998C26.7373 28.224 26.4988 28.1252 26.2503 28.1252C26.0017 28.1252 25.7632 28.224 25.5874 28.3998L23.7124 30.2748C23.5367 30.4506 23.438 30.689 23.438 30.9376C23.438 31.1862 23.5367 31.4247 23.7124 31.6004L27.4624 35.3504C27.6382 35.5262 27.8767 35.6249 28.1253 35.6249C28.3738 35.6249 28.6123 35.5262 28.7881 35.3504L35.3506 28.7879C35.5264 28.6122 35.625 28.3737 35.625 28.1251C35.625 27.8765 35.5264 27.6381 35.3506 27.4623ZM28.1253 33.362L25.7009 30.9376L26.2503 30.3883L27.4624 31.6004C27.6382 31.7762 27.8767 31.8749 28.1253 31.8749C28.3738 31.8749 28.6123 31.7762 28.7881 31.6004L32.8128 27.5758L33.3621 28.1251L28.1253 33.362Z"
          fill="#242424"
        />
      </g>
    </svg>
  );
}
