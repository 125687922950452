import React from "react";
import { SectionTitle, SpecialTitle } from "../utility";

export function ProductBenifits({ benifit }) {
  return (
    <section className="product">
      <div className="benifits">
        <div className="title-wraper">
          <SpecialTitle>Key Benifits</SpecialTitle>
          <SectionTitle>Key Benefits of using this product</SectionTitle>
        </div>
        <div className="card-wraper">{benifit}</div>
      </div>
    </section>
  );
}
