import React from "react";
import { SectionTitle } from "../utility";

export const FAQ = ({ children }) => {
  return (
    <section className="faq-section">
      <SectionTitle>Frequently Asked Questions</SectionTitle>
      <div className="accordian-cover">{children}</div>
    </section>
  );
};
