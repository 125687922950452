import React from "react";

export function SuitableForAll() {
  return (
    <svg
      width="60px"
      height="60px"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Optimum_Prices" clip-path="url(#clip0)">
        <g id="Suitable_Industries" clip-path="url(#clip1)">
          <g id="Group">
            <path
              id="Vector"
              d="M8.4375 23.3751L22.5 28.0626L36.5625 23.3751L30 36.5001L22.5 28.0626L15 36.5001L8.4375 23.3751Z"
              fill="white"
            />
            <path
              id="Vector_2"
              d="M22.5 57.1251L15 36.5001H30L22.5 57.1251Z"
              fill="white"
            />
            <path
              id="Vector_3"
              d="M46.875 0.875122C44.65 0.875122 42.4749 1.53492 40.6248 2.77109C38.7748 4.00726 37.3328 5.76427 36.4814 7.81993C35.6299 9.8756 35.4071 12.1376 35.8412 14.3199C36.2753 16.5022 37.3467 18.5067 38.9201 20.0801C40.4934 21.6534 42.498 22.7249 44.6802 23.159C46.8625 23.593 49.1245 23.3703 51.1802 22.5188C53.2359 21.6673 54.9929 20.2253 56.229 18.3753C57.4652 16.5252 58.125 14.3502 58.125 12.1251C58.125 9.14144 56.9397 6.27996 54.83 4.17017C52.7202 2.06039 49.8587 0.875122 46.875 0.875122ZM45 18.6876L39.375 13.0626L42.1875 10.2501L45 13.0626L51.5625 6.50012L54.375 9.31262L45 18.6876Z"
              fill="white"
            />
          </g>
          <path
            id="Vector_4"
            d="M46.875 -0.0623779C44.4645 -0.0623779 42.1082 0.652407 40.104 1.99159C38.0998 3.33077 36.5377 5.23419 35.6152 7.46117C34.6928 9.68814 34.4514 12.1386 34.9217 14.5028C35.3919 16.8669 36.5527 19.0385 38.2571 20.743C39.9616 22.4474 42.1332 23.6082 44.4973 24.0784C46.8615 24.5487 49.312 24.3073 51.539 23.3849C53.7659 22.4625 55.6694 20.9004 57.0085 18.8961C58.3477 16.8919 59.0625 14.5356 59.0625 12.1251C59.0588 8.89394 57.7735 5.79616 55.4888 3.51137C53.204 1.22658 50.1062 -0.0586561 46.875 -0.0623779ZM46.875 22.4376C44.8354 22.4376 42.8416 21.8328 41.1457 20.6997C39.4498 19.5665 38.128 17.9559 37.3475 16.0715C36.567 14.1872 36.3627 12.1137 36.7607 10.1133C37.1586 8.11282 38.1407 6.27531 39.583 4.83308C41.0252 3.39085 42.8627 2.40868 44.8631 2.01077C46.8636 1.61286 48.9371 1.81709 50.8214 2.59761C52.7058 3.37814 54.3164 4.69992 55.4495 6.3958C56.5827 8.09169 57.1875 10.0855 57.1875 12.1251C57.1843 14.8592 56.0967 17.4803 54.1635 19.4136C52.2302 21.3469 49.6091 22.4344 46.875 22.4376Z"
            fill="#242424"
          />
          <path
            id="Vector_5"
            d="M55.0376 8.64985L52.2251 5.83735C52.0493 5.6616 51.8109 5.56287 51.5623 5.56287C51.3137 5.56287 51.0753 5.6616 50.8995 5.83735L44.9998 11.737L42.8501 9.58735C42.6743 9.4116 42.4359 9.31287 42.1873 9.31287C41.9387 9.31287 41.7003 9.4116 41.5245 9.58735L38.712 12.3999C38.5362 12.5757 38.4375 12.8141 38.4375 13.0627C38.4375 13.3113 38.5362 13.5497 38.712 13.7255L44.337 19.3505C44.5128 19.5262 44.7512 19.625 44.9998 19.625C45.2484 19.625 45.4868 19.5262 45.6626 19.3505L55.0376 9.97548C55.2134 9.79967 55.3121 9.56126 55.3121 9.31267C55.3121 9.06407 55.2134 8.82566 55.0376 8.64985ZM44.9998 17.362L40.7004 13.0627L42.1873 11.5758L44.337 13.7255C44.5128 13.9012 44.7512 14 44.9998 14C45.2484 14 45.4868 13.9012 45.6626 13.7255L51.5623 7.82579L53.0492 9.31267L44.9998 17.362Z"
            fill="#242424"
          />
          <path
            id="Vector_6"
            d="M37.3304 22.8379C37.2043 22.6566 37.0179 22.526 36.8044 22.4695L22.7419 18.7195C22.5835 18.6772 22.4167 18.6772 22.2582 18.7195L8.19568 22.4695C7.98222 22.526 7.79581 22.6566 7.66975 22.8379L1.10725 32.2129C0.988645 32.3825 0.92948 32.5865 0.938976 32.7933C0.948473 33 1.0261 33.1977 1.15975 33.3557L21.7847 57.7307C21.8728 57.8346 21.9823 57.9181 22.1059 57.9753C22.2294 58.0326 22.3639 58.0622 22.5001 58.0622C22.6362 58.0622 22.7707 58.0326 22.8943 57.9753C23.0178 57.9181 23.1274 57.8346 23.2154 57.7307L43.8404 33.3557C43.974 33.1977 44.0516 33 44.0611 32.7933C44.0706 32.5865 44.0115 32.3825 43.8929 32.2129L37.3304 22.8379ZM22.5001 29.4735L27.9122 35.5626H17.0879L22.5001 29.4735ZM28.6613 37.4376L22.5001 54.381L16.3388 37.4376H28.6613ZM29.7807 34.8417L24.1454 28.5023L34.7119 24.9801L29.7807 34.8417ZM36.6976 25.2023L41.6026 32.2101L31.7916 35.0132L36.6976 25.2023ZM15.2194 34.8417L10.2882 24.9801L20.8547 28.5023L15.2194 34.8417ZM13.2085 35.0132L3.39756 32.2101L8.30256 25.2023L13.2085 35.0132ZM14.2819 37.2698L19.8244 52.5117L4.58256 34.4985L14.2819 37.2698ZM30.7182 37.2698L40.4176 34.4985L25.1757 52.5117L30.7182 37.2698ZM22.5001 20.5954L33.2982 23.4745L22.5001 27.0745L11.7019 23.4745L22.5001 20.5954Z"
            fill="#242424"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="60"
            height="60"
            fill="white"
            transform="translate(0 0.00012207)"
          />
        </clipPath>
        <clipPath id="clip1">
          <rect
            width="60"
            height="60"
            fill="white"
            transform="translate(0 -0.999878)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
