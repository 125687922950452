import React from "react";
import { ClickArrow } from "../../svgs/Patterns_Svgs";
import { Button, Paragraph, SectionTitle, Title } from "../utility";

export function CallToAction() {
  return (
    <section className="cta-cover">
      <div className="wrapper">
        <div className="heading-wrapper">
          <div className="heading-cover">
            <SectionTitle>Need Business Products?</SectionTitle>
          </div>
          <div className="subheading-cover">
            <Title> Leave it to us.</Title>
          </div>
        </div>
        <div className="content-button-cover">
          <Paragraph>
            Fill in our form and get obligation-free quotes from top brands and
            suppliers.
          </Paragraph>
          <Button to="/">Get quote</Button>
          <ClickArrow className="click-arrow" />
        </div>
      </div>
    </section>
  );
}
