import React from "react";
import { Card } from "../utility/Card";
import { WhyDigital } from "../../data";
import { Button, SectionTitle, SpecialTitle } from "../utility";
import { Product_Routes } from "../../data/Routes";

export function WhySection() {
  return (
    <section className="why-section">
      <div className="why-wrapper">
        <div className="title-wraper">
          <SpecialTitle>Benefits Of Putting In Details</SpecialTitle>
          <SectionTitle>Why Digital Comparison?</SectionTitle>
        </div>
        <div className="card-wraper">
          {WhyDigital.map((item, id) => {
            return (
              <Card
                key={id}
                icon={<item.src />}
                title={item.title}
                paragraph={item.paragraph}
              />
            );
          })}
        </div>
        <Button to={Product_Routes.Aboutus}>Read more</Button>
      </div>
    </section>
  );
}
