import { Link } from "gatsby";
import React from "react";
import { SectionTitle, SpecialTitle, Title } from "../utility";

import BusinessPhoneSystem from "../../svgs/ServiceSvgs/Phone";
import CopierandPrinter from "../../svgs/ServiceSvgs/Printer";
import BusinessSolar from "../../svgs/ServiceSvgs/Solar";
import ITSolutions from "../../svgs/ServiceSvgs/Solutions";
import CyberSecurity from "../../svgs/ServiceSvgs/Cyber";
import DigitalMarketing from "../../svgs/ServiceSvgs/DigitalMarketing";
import BusinessEnergy from "../../svgs/ServiceSvgs/Energy";
import ITHardware from "../../svgs/ServiceSvgs/Hardware";
import BusinessInternet from "../../svgs/ServiceSvgs/Internet";
import NavigationSystm from "../../svgs/ServiceSvgs/Navigation";

import { Product_Routes } from "../../data/Routes";

const Service = [
  {
    title: "Business Phone System",
    icon: BusinessPhoneSystem,
    to: Product_Routes.BusinessPhoneSystem,
  },
  {
    title: "Business Internet",
    icon: BusinessInternet,
    to: Product_Routes.BusinessInternet,
  },
  {
    title: "Business Solar",
    icon: BusinessSolar,
    to: Product_Routes.BusinessSolar,
  },
  {
    title: "Business Energy",
    icon: BusinessEnergy,
    to: Product_Routes.BusinessEnergy,
  },
  {
    title: "IT Solutions",
    icon: ITSolutions,
    to: Product_Routes.ITSolutions,
  },
  {
    title: "IT Hardware",
    icon: ITHardware,
    to: Product_Routes.ITHardware,
  },
  {
    title: "IT Cyber Security",
    icon: CyberSecurity,
    to: Product_Routes.CyberSecurity,
  },
  {
    title: "Copier and Printer",
    icon: CopierandPrinter,
    to: Product_Routes.CopierandPrinter,
  },
  {
    title: "Navigation System",
    icon: NavigationSystm,
    to: Product_Routes.NavigationSystem,
  },
  {
    title: "Digital Web Marketing",
    icon: DigitalMarketing,
    to: Product_Routes.DigitalWebMarketing,
  },
];

export function ServicesMenuSection() {
  return (
    <section id="productandservices" className="service-section">
      <div className="service-wrapper">
        <div className="service-title-wrapper">
          <SpecialTitle>Compare & Save From Below</SpecialTitle>
          <SectionTitle>Products and Services</SectionTitle>
        </div>
        <div className="service-grid">
          {Service.map((item, index) => {
            return (
              <div className="link-cover" key={index}>
                <Link to={item.to} className="service-link">
                  <div className="service-link-wrapper">
                    {<item.icon />}
                    <Title>{item.title}</Title>
                  </div>
                </Link>
              </div>
            );
          })}
          <Link to={Product_Routes.Contactus} className="highlight">
            <div className="service-link-wrapper">
              <Title>Something else?</Title>
            </div>
          </Link>
        </div>
      </div>
    </section>
  );
}
