import React from "react";
import { Button, SectionTitle } from "../utility";
import Benifit1 from "../../svgs/Patterns_Svgs/Benifit_1.svg";
import Benifit2 from "../../svgs/Patterns_Svgs/Benifit_2.svg";
import Benifit3 from "../../svgs/Patterns_Svgs/Benifit_3.svg";
import Benifit4 from "../../svgs/Patterns_Svgs/Benifit_4.svg";

export function ProductDescription({
  title,
  paragraph,
  to,
  profitPoint1,
  profitPoint2,
  profitPoint3,
  profitPoint4,
}) {
  return (
    <section className="product-description">
      <div className="product-description-wrapper">
        <div className="description-title-container">
          <h3 className="finance-speacial-title">Description</h3>
          <SectionTitle>{title}</SectionTitle>
        </div>
        <div className="description-paragraph">
          <p className="details-paragraph">{paragraph}</p>
        </div>
        <div className="finance-benifits">
          <h3 className="finance-speacial-title">
            Finacially Focused Benifits
          </h3>
          <h1 className="finance-title">How Your Business Will Profit?</h1>
          <div className="profits-wrapper">
            <div className="profits-card">
              <div className="card-icon">
                <img className="benifit-icons" src={Benifit1} alt={Benifit1} />
              </div>
              <div className="profit-details">
                <p>{profitPoint1}</p>
              </div>
            </div>
            <div className="profits-card">
              <div className="card-icon">
                <img className="benifit-icons" src={Benifit2} alt={Benifit2} />
              </div>
              <div className="profit-details">
                <p>{profitPoint2}</p>
              </div>
            </div>
            <div className="profits-card">
              <div className="card-icon">
                <img className="benifit-icons" src={Benifit3} alt={Benifit3} />
              </div>
              <div className="profit-details">
                <p>{profitPoint3}</p>
              </div>
            </div>
            <div className="profits-card">
              <div className="card-icon">
                <img className="benifit-icons" src={Benifit4} alt={Benifit4} />
              </div>
              <div className="profit-details">
                <p>{profitPoint4}</p>
              </div>
            </div>
          </div>
          <Button to={to}>Compare now</Button>
        </div>
      </div>
    </section>
  );
}
