import React from "react";
import { ClickCompareArrow } from "../../svgs/Patterns_Svgs";
import { Button } from "../utility";

export function ProductHeader({ src, subTitle, title, paragraph, to }) {
  return (
    <header className="product-header">
      <img className="product-header-image" src={src} alt={src} />
      <div className="blur-bg">
        <div className="content-wrapper">
          <div className="product-header-content">
            <div className="product-header-subtitle">{subTitle}</div>
            <div className="product-header-title">{title}</div>
            <div className="product-header-paragraph">{paragraph}</div>
          </div>
          <div className="button-arrow-wrapper">
            <Button to={to}>Compare now</Button>
            <ClickCompareArrow className="product-arrow" />
          </div>
        </div>
      </div>
    </header>
  );
}
