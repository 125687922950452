import React from "react";

export function TimeStres() {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="TimeStres">
        <path
          id="Vector"
          d="M39.375 1.875C29.0194 1.875 20.625 10.2694 20.625 20.625C20.625 30.9806 29.0194 39.375 39.375 39.375C49.7306 39.375 58.125 30.9806 58.125 20.625C58.125 10.2694 49.7306 1.875 39.375 1.875ZM39.375 36.5625C30.5728 36.5625 23.4375 29.4272 23.4375 20.625C23.4375 11.8228 30.5728 4.6875 39.375 4.6875C48.1772 4.6875 55.3125 11.8228 55.3125 20.625C55.3125 29.4272 48.1772 36.5625 39.375 36.5625Z"
          fill="white"
        />
        <path
          id="Vector_2"
          d="M10.3125 18.75C8.75906 18.75 7.5 17.4909 7.5 15.9375V14.0625C7.5 12.5091 8.75906 11.25 10.3125 11.25C11.8659 11.25 13.125 12.5091 13.125 14.0625V15.9375C13.125 17.4909 11.8659 18.75 10.3125 18.75Z"
          fill="white"
        />
        <path
          id="Vector_3"
          d="M14.0625 15.9375V14.0625C14.0625 11.9944 12.3806 10.3125 10.3125 10.3125C8.24437 10.3125 6.5625 11.9944 6.5625 14.0625V15.9375C6.5625 18.0056 8.24437 19.6875 10.3125 19.6875C12.3806 19.6875 14.0625 18.0056 14.0625 15.9375ZM12.1875 15.9375C12.1875 16.9716 11.3466 17.8125 10.3125 17.8125C9.27844 17.8125 8.4375 16.9716 8.4375 15.9375V14.0625C8.4375 13.0284 9.27844 12.1875 10.3125 12.1875C11.3466 12.1875 12.1875 13.0284 12.1875 14.0625V15.9375Z"
          fill="#242424"
        />
        <path
          id="Vector_4"
          d="M3.75 32.8125V53.61C2.66156 53.9981 1.875 55.0294 1.875 56.25V58.125C1.875 58.6434 2.295 59.0625 2.8125 59.0625H8.4375C8.955 59.0625 9.375 58.6434 9.375 58.125V54.375V40.3125H11.25V54.375V58.125C11.25 58.6434 11.67 59.0625 12.1875 59.0625H17.8125C18.33 59.0625 18.75 58.6434 18.75 58.125V56.25C18.75 55.0294 17.9634 53.9981 16.875 53.61V32.8125C18.4256 32.8125 19.6875 31.5506 19.6875 30V24.375C19.6875 21.7903 17.5847 19.6875 15 19.6875H10.3125H5.625C3.04031 19.6875 0.9375 21.7903 0.9375 24.375V30C0.9375 31.5506 2.19937 32.8125 3.75 32.8125ZM7.5 57.1875H3.75V56.25C3.75 55.7325 4.17094 55.3125 4.6875 55.3125H7.5V57.1875ZM8.4375 38.4375C7.92 38.4375 7.5 38.8566 7.5 39.375V53.4375H5.625V36.5625H15V53.4375H13.125V39.375C13.125 38.8566 12.705 38.4375 12.1875 38.4375H8.4375ZM16.875 57.1875H13.125V55.3125H15.9375C16.4541 55.3125 16.875 55.7325 16.875 56.25V57.1875ZM15 34.6875H5.625V32.8125H8.4375C8.51438 32.8125 8.59031 32.8031 8.66531 32.7844L11.7084 32.0231C12.6647 32.5416 13.7316 32.8125 14.8284 32.8125H15V34.6875ZM2.8125 24.375C2.8125 22.8244 4.07437 21.5625 5.625 21.5625H15C16.5506 21.5625 17.8125 22.8244 17.8125 24.375V30C17.8125 30.5175 17.3916 30.9375 16.875 30.9375H14.8284C13.8994 30.9375 13.0003 30.6656 12.2278 30.15L10.8328 29.22C10.7634 29.1731 10.6884 29.1366 10.6097 29.1113L7.65094 28.125H11.0691L15.5888 29.9334C15.7022 29.9784 15.8203 30 15.9375 30C16.1222 30 16.3059 29.9447 16.4634 29.8387C16.7203 29.6644 16.875 29.3737 16.875 29.0625V25.3125H15V27.6778L11.5987 26.3166C11.4872 26.2725 11.3691 26.25 11.25 26.25H5.625V25.3125H3.75V30H5.625V29.4253L9.37313 30.675L8.32219 30.9375H3.75C3.23344 30.9375 2.8125 30.5175 2.8125 30V24.375Z"
          fill="#242424"
        />
        <path
          id="Vector_5"
          d="M39.375 0.9375C28.5188 0.9375 19.6875 9.76969 19.6875 20.625C19.6875 31.4803 28.5188 40.3125 39.375 40.3125C50.2312 40.3125 59.0625 31.4803 59.0625 20.625C59.0625 9.76969 50.2312 0.9375 39.375 0.9375ZM39.375 38.4375C29.5528 38.4375 21.5625 30.4472 21.5625 20.625C21.5625 10.8028 29.5528 2.8125 39.375 2.8125C49.1972 2.8125 57.1875 10.8028 57.1875 20.625C57.1875 30.4472 49.1972 38.4375 39.375 38.4375Z"
          fill="#242424"
        />
        <path
          id="Vector_6"
          d="M39.375 3.75C30.0703 3.75 22.5 11.3203 22.5 20.625C22.5 29.9297 30.0703 37.5 39.375 37.5C48.6797 37.5 56.25 29.9297 56.25 20.625C56.25 11.3203 48.6797 3.75 39.375 3.75ZM39.375 35.625C31.1044 35.625 24.375 28.8956 24.375 20.625C24.375 12.3544 31.1044 5.625 39.375 5.625C47.6456 5.625 54.375 12.3544 54.375 20.625C54.375 28.8956 47.6456 35.625 39.375 35.625Z"
          fill="#242424"
        />
        <path
          id="Vector_7"
          d="M48.0703 19.7231L42.0684 18.0084L40.2975 8.26969C40.2159 7.82438 39.8278 7.5 39.375 7.5C38.9222 7.5 38.5341 7.82438 38.4525 8.26969L36.6778 18.0309C36.0291 18.7059 35.625 19.6172 35.625 20.625C35.625 22.6931 37.3069 24.375 39.375 24.375C40.4222 24.375 41.3681 23.9419 42.0488 23.2472L48.0703 21.5269C48.4725 21.4116 48.75 21.0431 48.75 20.625C48.75 20.2069 48.4725 19.8384 48.0703 19.7231ZM39.9675 16.935C39.7725 16.9041 39.5775 16.875 39.375 16.875C39.1725 16.875 38.9775 16.9041 38.7825 16.935L39.375 13.6781L39.9675 16.935ZM39.375 22.5C38.3409 22.5 37.5 21.6591 37.5 20.625C37.5 19.5909 38.3409 18.75 39.375 18.75C40.4091 18.75 41.25 19.5909 41.25 20.625C41.25 21.6591 40.4091 22.5 39.375 22.5ZM43.0875 21C43.0997 20.8744 43.125 20.7534 43.125 20.625C43.125 20.4966 43.0997 20.3756 43.0875 20.25L44.4 20.625L43.0875 21Z"
          fill="#242424"
        />
        <path
          id="Vector_8"
          d="M50.625 19.6875H52.5V21.5625H50.625V19.6875Z"
          fill="#242424"
        />
        <path
          id="Vector_9"
          d="M38.4375 31.875H40.3125V33.75H38.4375V31.875Z"
          fill="#242424"
        />
        <path
          id="Vector_10"
          d="M26.25 19.6875H28.125V21.5625H26.25V19.6875Z"
          fill="#242424"
        />
      </g>
    </svg>
  );
}
