import React from "react";

export default function Printer() {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Printer">
        <g id="Group">
          <g id="Group_2">
            <g id="Group_3">
              <path
                id="Vector"
                d="M49.1667 16.6667H45V10.8333C45 10.3731 44.6269 10 44.1667 10H41.6667V0.833333C41.6667 0.373125 41.2935 0 40.8333 0H9.16667C8.70646 0 8.33333 0.373125 8.33333 0.833333V10H5.83333C5.37313 10 5 10.3731 5 10.8333V16.6667H0.833333C0.373125 16.6667 0 17.0398 0 17.5V40.8333C0 41.2935 0.373125 41.6667 0.833333 41.6667H8.33333V49.1667C8.33333 49.6269 8.70646 50 9.16667 50H40.8333C41.2935 50 41.6667 49.6269 41.6667 49.1667V41.6667H49.1667C49.6269 41.6667 50 41.2935 50 40.8333V17.5C50 17.0398 49.6269 16.6667 49.1667 16.6667ZM41.6667 11.6667H43.3333V16.6667H41.6667V11.6667ZM10 1.66667H40V16.6667H10V1.66667ZM6.66667 11.6667H8.33333V16.6667H6.66667V11.6667ZM8.33333 36.6667H7.5V34.1667H8.33333V36.6667ZM40 48.3333H10V34.1667H40V48.3333ZM48.3333 40H41.6667V38.3333H43.3333C43.7935 38.3333 44.1667 37.9602 44.1667 37.5V33.3333C44.1667 32.8731 43.7935 32.5 43.3333 32.5H6.66667C6.20646 32.5 5.83333 32.8731 5.83333 33.3333V37.5C5.83333 37.9602 6.20646 38.3333 6.66667 38.3333H8.33333V40H1.66667V18.3333H48.3333V40ZM41.6667 36.6667V34.1667H42.5V36.6667H41.6667Z"
                fill="white"
              />
            </g>
          </g>
          <g id="Group_4">
            <g id="Group_5">
              <path
                id="Vector_2"
                d="M36.6668 36.6667H18.3335V38.3333H36.6668V36.6667Z"
                fill="white"
              />
            </g>
          </g>
          <g id="Group_6">
            <g id="Group_7">
              <path
                id="Vector_3"
                d="M36.6668 40H13.3335V41.6667H36.6668V40Z"
                fill="white"
              />
            </g>
          </g>
          <g id="Group_8">
            <g id="Group_9">
              <path
                id="Vector_4"
                d="M31.6668 43.3333H13.3335V45H31.6668V43.3333Z"
                fill="white"
              />
            </g>
          </g>
          <g id="Group_10">
            <g id="Group_11">
              <path
                id="Vector_5"
                d="M16.6668 36.6667H13.3335V38.3333H16.6668V36.6667Z"
                fill="white"
              />
            </g>
          </g>
          <g id="Group_12">
            <g id="Group_13">
              <path
                id="Vector_6"
                d="M10.8335 20H4.16683C3.70662 20 3.3335 20.3731 3.3335 20.8333V24.1667C3.3335 24.6269 3.70662 25 4.16683 25H10.8335C11.2937 25 11.6668 24.6269 11.6668 24.1667V20.8333C11.6668 20.3731 11.2937 20 10.8335 20ZM10.0002 23.3333H5.00016V21.6667H10.0002V23.3333Z"
                fill="white"
              />
            </g>
          </g>
          <g id="Group_14">
            <g id="Group_15">
              <path
                id="Vector_7"
                d="M21.6668 23.3333H13.3335V25H21.6668V23.3333Z"
                fill="white"
              />
            </g>
          </g>
          <g id="Group_16">
            <g id="Group_17">
              <path
                id="Vector_8"
                d="M25.0002 23.3333H23.3335V25H25.0002V23.3333Z"
                fill="white"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
