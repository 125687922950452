import {
  HiddenCost,
  NoObligation,
  PersonalisedService,
  ProtectPrivacy,
  TeamExperts,
  TimeStres,
} from "../svgs/WhyDigital_Svgs";

export const WhyDigital = [
  {
    id: 1,
    src: HiddenCost,
    title: "No Hidden Costs",
    paragraph: "Our service is completely free.",
  },
  {
    id: 2,
    src: TimeStres,
    title: "Save Time And Stress",
    paragraph: "Sit back and relax while we bring the quotes to you.",
  },
  {
    id: 3,
    src: NoObligation,
    title: "No Obligations",
    paragraph: "All offers are obligation and pressure free.",
  },
  {
    id: 4,
    src: ProtectPrivacy,
    title: "Protect Your Privacy",
    paragraph:
      "We’ll only hand your details to the select few suppliers who meet your needs.",
  },
  {
    id: 5,
    src: PersonalisedService,
    title: "Personalised Service",
    paragraph: "All offers are tailored to your specific circumstances.",
  },
  {
    id: 6,
    src: TeamExperts,
    title: "Team Of Experts",
    paragraph: "We know how to connect you with the solutions you need.",
  },
];
