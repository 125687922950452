import {
  QualifiedSuppliers,
  OptimumPrices,
  PremiumProducts,
  SuitableForAll,
  CustomisedSolutions,
  PurchasesAndUpgrades,
} from "../svgs/WhyLove_Svgs";

export const WhyLove = [
  {
    id: 1,
    src: OptimumPrices,
    title: "Optimum Price",
    paragraph: "We find you the most cost-effective prices.",
  },
  {
    id: 2,
    src: PremiumProducts,
    title: "Premium Products",
    paragraph: "Choose from a range of first-class products",
  },
  {
    id: 3,
    src: QualifiedSuppliers,
    title: "Qualified Suppliers",
    paragraph: "Our suppliers are qualified industry leaders.",
  },
  {
    id: 4,
    src: CustomisedSolutions,
    title: "Customised Solutions",
    paragraph:
      "Connect with products and suppliers matching your unique needs.",
  },
  {
    id: 5,
    src: PurchasesAndUpgrades,
    title: "New Purchases And Upgrades",
    paragraph: "Install brand-new systems or upgrade your existing products.",
  },
  {
    id: 6,
    src: SuitableForAll,
    title: "Suitable For All Industries",
    paragraph: "No matter your field, we have your ideal business solutions.",
  },
];
