import React from "react";
import { IconCover } from "./IconCover";

export function Card({ icon, title, paragraph }) {
  return (
    <div className="card-cover">
      <IconCover>{icon}</IconCover>
      <div className="card-text">
        <h2 className="benifit-title">{title}</h2>
        <p className="card-paragraph">{paragraph}</p>
      </div>
    </div>
  );
}
