import React from "react";

export default function Cyber() {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Cyber">
        <g id="Group">
          <path
            id="Vector"
            d="M3.33333 10C1.495 10 0 8.50501 0 6.66668C0 4.82834 1.495 3.33334 3.33333 3.33334C5.17167 3.33334 6.66667 4.82834 6.66667 6.66668C6.66667 8.50501 5.17167 10 3.33333 10ZM3.33333 5.00001C2.41417 5.00001 1.66667 5.74751 1.66667 6.66668C1.66667 7.58584 2.41417 8.33334 3.33333 8.33334C4.2525 8.33334 5 7.58584 5 6.66668C5 5.74751 4.2525 5.00001 3.33333 5.00001Z"
            fill="white"
          />
          <path
            id="Vector_2"
            d="M15.8333 6.66667C13.995 6.66667 12.5 5.17167 12.5 3.33333C12.5 1.495 13.995 0 15.8333 0C17.6717 0 19.1667 1.495 19.1667 3.33333C19.1667 5.17167 17.6717 6.66667 15.8333 6.66667ZM15.8333 1.66667C14.9142 1.66667 14.1667 2.41417 14.1667 3.33333C14.1667 4.2525 14.9142 5 15.8333 5C16.7525 5 17.5 4.2525 17.5 3.33333C17.5 2.41417 16.7525 1.66667 15.8333 1.66667Z"
            fill="white"
          />
          <path
            id="Vector_3"
            d="M46.6666 17.5C44.8282 17.5 43.3333 16.005 43.3333 14.1667C43.3333 12.3283 44.8282 10.8333 46.6666 10.8333C48.5049 10.8333 49.9999 12.3283 49.9999 14.1667C49.9999 16.005 48.5049 17.5 46.6666 17.5ZM46.6666 12.5C45.7474 12.5 44.9999 13.2475 44.9999 14.1667C44.9999 15.0858 45.7474 15.8333 46.6666 15.8333C47.5857 15.8333 48.3332 15.0858 48.3332 14.1667C48.3332 13.2475 47.5857 12.5 46.6666 12.5Z"
            fill="white"
          />
          <path
            id="Vector_4"
            d="M35.8333 50C33.995 50 32.5 48.505 32.5 46.6667C32.5 44.8283 33.995 43.3333 35.8333 43.3333C37.6717 43.3333 39.1667 44.8283 39.1667 46.6667C39.1667 48.505 37.6717 50 35.8333 50ZM35.8333 45C34.9142 45 34.1667 45.7475 34.1667 46.6667C34.1667 47.5858 34.9142 48.3333 35.8333 48.3333C36.7525 48.3333 37.5 47.5858 37.5 46.6667C37.5 45.7475 36.7525 45 35.8333 45Z"
            fill="white"
          />
          <path
            id="Vector_5"
            d="M46.6666 40.8333C44.8282 40.8333 43.3333 39.3383 43.3333 37.5C43.3333 35.6617 44.8282 34.1667 46.6666 34.1667C48.5049 34.1667 49.9999 35.6617 49.9999 37.5C49.9999 39.3383 48.5049 40.8333 46.6666 40.8333ZM46.6666 35.8333C45.7474 35.8333 44.9999 36.5808 44.9999 37.5C44.9999 38.4192 45.7474 39.1667 46.6666 39.1667C47.5857 39.1667 48.3332 38.4192 48.3332 37.5C48.3332 36.5808 47.5857 35.8333 46.6666 35.8333Z"
            fill="white"
          />
          <path
            id="Vector_6"
            d="M10.8333 47.5C8.995 47.5 7.5 46.005 7.5 44.1667C7.5 42.3283 8.995 40.8333 10.8333 40.8333C12.6717 40.8333 14.1667 42.3283 14.1667 44.1667C14.1667 46.005 12.6717 47.5 10.8333 47.5ZM10.8333 42.5C9.91417 42.5 9.16667 43.2475 9.16667 44.1667C9.16667 45.0858 9.91417 45.8333 10.8333 45.8333C11.7525 45.8333 12.5 45.0858 12.5 44.1667C12.5 43.2475 11.7525 42.5 10.8333 42.5Z"
            fill="white"
          />
          <path
            id="Vector_7"
            d="M3.33333 37.5C1.495 37.5 0 36.005 0 34.1666C0 32.3283 1.495 30.8333 3.33333 30.8333C5.17167 30.8333 6.66667 32.3283 6.66667 34.1666C6.66667 36.005 5.17167 37.5 3.33333 37.5ZM3.33333 32.5C2.41417 32.5 1.66667 33.2475 1.66667 34.1666C1.66667 35.0858 2.41417 35.8333 3.33333 35.8333C4.2525 35.8333 5 35.0858 5 34.1666C5 33.2475 4.2525 32.5 3.33333 32.5Z"
            fill="white"
          />
          <path
            id="Vector_8"
            d="M38.3333 6.66667C36.495 6.66667 35 5.17167 35 3.33333C35 1.495 36.495 0 38.3333 0C40.1717 0 41.6667 1.495 41.6667 3.33333C41.6667 5.17167 40.1717 6.66667 38.3333 6.66667ZM38.3333 1.66667C37.4142 1.66667 36.6667 2.41417 36.6667 3.33333C36.6667 4.2525 37.4142 5 38.3333 5C39.2525 5 40 4.2525 40 3.33333C40 2.41417 39.2525 1.66667 38.3333 1.66667Z"
            fill="white"
          />
          <path
            id="Vector_9"
            d="M25 40C16.7292 40 10 33.2708 10 25C10 16.7292 16.7292 10 25 10C33.2708 10 40 16.7292 40 25C40 33.2708 33.2708 40 25 40ZM25 11.6667C17.6475 11.6667 11.6667 17.6483 11.6667 25C11.6667 32.3517 17.6475 38.3333 25 38.3333C32.3517 38.3333 38.3333 32.3517 38.3333 25C38.3333 17.6483 32.3517 11.6667 25 11.6667Z"
            fill="white"
          />
          <path
            id="Vector_10"
            d="M14.1667 16.6667H10C5.86417 16.6667 2.5 13.3017 2.5 9.16668C2.5 8.70668 2.87333 8.33334 3.33333 8.33334C3.79333 8.33334 4.16667 8.70668 4.16667 9.16668C4.16667 12.3833 6.78333 15 10 15H14.1667C14.6267 15 15 15.3733 15 15.8333C15 16.2933 14.6267 16.6667 14.1667 16.6667Z"
            fill="white"
          />
          <path
            id="Vector_11"
            d="M20 45H13.3333C12.8733 45 12.5 44.6267 12.5 44.1667C12.5 43.7067 12.8733 43.3333 13.3333 43.3333H20C22.2975 43.3333 24.1667 41.4642 24.1667 39.1666C24.1667 38.7066 24.54 38.3333 25 38.3333C25.46 38.3333 25.8333 38.7066 25.8333 39.1666C25.8333 42.3833 23.2167 45 20 45Z"
            fill="white"
          />
          <path
            id="Vector_12"
            d="M14.1667 35H5.83333C5.37333 35 5 34.6267 5 34.1667C5 33.7067 5.37333 33.3333 5.83333 33.3333H14.1667C14.6267 33.3333 15 33.7067 15 34.1667C15 34.6267 14.6267 35 14.1667 35Z"
            fill="white"
          />
          <path
            id="Vector_13"
            d="M25 6.6667C24.54 6.6667 24.1667 6.29336 24.1667 5.83336C24.1667 4.9142 23.4192 4.1667 22.5 4.1667H18.3333C17.8733 4.1667 17.5 3.79336 17.5 3.33336C17.5 2.87336 17.8733 2.50003 18.3333 2.50003H22.5C24.3383 2.50003 25.8333 3.99503 25.8333 5.83336C25.8333 6.29336 25.46 6.6667 25 6.6667Z"
            fill="white"
          />
          <path
            id="Vector_14"
            d="M25.0001 11.6667C24.5401 11.6667 24.1667 11.2933 24.1667 10.8333V5.83333C24.1667 5.37333 24.5401 5 25.0001 5C25.4601 5 25.8334 5.37333 25.8334 5.83333V10.8333C25.8334 11.2933 25.4601 11.6667 25.0001 11.6667Z"
            fill="white"
          />
          <path
            id="Vector_15"
            d="M31.6666 13.3333C31.2066 13.3333 30.8333 12.96 30.8333 12.5C30.8333 10.6617 32.3283 9.16667 34.1666 9.16667H36.6666C37.1257 9.16667 37.4999 8.7925 37.4999 8.33333V5.83333C37.4999 5.37333 37.8732 5 38.3332 5C38.7932 5 39.1666 5.37333 39.1666 5.83333V8.33333C39.1666 9.71167 38.0449 10.8333 36.6666 10.8333H34.1666C33.2474 10.8333 32.4999 11.5808 32.4999 12.5C32.4999 12.96 32.1266 13.3333 31.6666 13.3333Z"
            fill="white"
          />
          <path
            id="Vector_16"
            d="M39.1666 25.8333C38.7066 25.8333 38.3333 25.46 38.3333 25C38.3333 23.1616 39.8283 21.6666 41.6666 21.6666H44.9999C45.4591 21.6666 45.8333 21.2925 45.8333 20.8333V16.6666C45.8333 16.2066 46.2066 15.8333 46.6666 15.8333C47.1266 15.8333 47.4999 16.2066 47.4999 16.6666V20.8333C47.4999 22.2116 46.3783 23.3333 44.9999 23.3333H41.6666C40.7474 23.3333 39.9999 24.0808 39.9999 25C39.9999 25.46 39.6266 25.8333 39.1666 25.8333Z"
            fill="white"
          />
          <path
            id="Vector_17"
            d="M46.6667 35.8333C46.2067 35.8333 45.8333 35.46 45.8333 35V32.5C45.8333 32.0408 45.4592 31.6667 45 31.6667H40.8333C39.1008 31.6667 37.9375 30.3275 37.9375 28.3333C37.9375 27.8733 38.3108 27.5 38.7708 27.5C39.2308 27.5 39.6042 27.8733 39.6042 28.3333C39.6042 28.835 39.7242 30 40.8333 30H45C46.3783 30 47.5 31.1217 47.5 32.5V35C47.5 35.46 47.1267 35.8333 46.6667 35.8333Z"
            fill="white"
          />
          <path
            id="Vector_18"
            d="M35.8332 42.5C35.3732 42.5 34.9999 42.1267 34.9999 41.6667C34.9999 41.2075 34.6258 40.8334 34.1666 40.8334C32.3283 40.8334 30.8333 39.3384 30.8333 37.5C30.8333 37.04 31.2066 36.6667 31.6666 36.6667C32.1266 36.6667 32.4999 37.04 32.4999 37.5C32.4999 38.4192 33.2474 39.1667 34.1666 39.1667C35.5449 39.1667 36.6666 40.2884 36.6666 41.6667C36.6666 42.1267 36.2932 42.5 35.8332 42.5Z"
            fill="white"
          />
          <path
            id="Vector_19"
            d="M35.8333 45C35.3733 45 35 44.6267 35 44.1667V41.6667C35 41.2067 35.3733 40.8333 35.8333 40.8333C36.2933 40.8333 36.6666 41.2067 36.6666 41.6667V44.1667C36.6666 44.6267 36.2933 45 35.8333 45Z"
            fill="white"
          />
          <path
            id="Vector_20"
            d="M31.6667 34.1667H18.3333C17.8733 34.1667 17.5 33.7933 17.5 33.3333V23.3333C17.5 22.8733 17.8733 22.5 18.3333 22.5H31.6667C32.1267 22.5 32.5 22.8733 32.5 23.3333V33.3333C32.5 33.7933 32.1267 34.1667 31.6667 34.1667ZM19.1667 32.5H30.8333V24.1667H19.1667V32.5Z"
            fill="white"
          />
          <path
            id="Vector_21"
            d="M27.5001 30.8333H22.5001C22.0401 30.8333 21.6667 30.46 21.6667 30V26.6666C21.6667 26.2066 22.0401 25.8333 22.5001 25.8333H27.5001C27.9601 25.8333 28.3334 26.2066 28.3334 26.6666V30C28.3334 30.46 27.9601 30.8333 27.5001 30.8333ZM23.3334 29.1667H26.6667V27.5H23.3334V29.1667Z"
            fill="white"
          />
          <path
            id="Vector_22"
            d="M30.0001 24.1667H20.0001C19.5401 24.1667 19.1667 23.7933 19.1667 23.3333V20C19.1667 16.7833 21.7834 14.1667 25.0001 14.1667C28.2167 14.1667 30.8334 16.7833 30.8334 20V23.3333C30.8334 23.7933 30.4601 24.1667 30.0001 24.1667ZM20.8334 22.5H29.1667V20C29.1667 17.7025 27.2976 15.8333 25.0001 15.8333C22.7026 15.8333 20.8334 17.7025 20.8334 20V22.5Z"
            fill="white"
          />
          <path
            id="Vector_23"
            d="M26.6667 24.1666H23.3333C22.8733 24.1666 22.5 23.7933 22.5 23.3333V20C22.5 18.6216 23.6217 17.5 25 17.5C26.3783 17.5 27.5 18.6216 27.5 20V23.3333C27.5 23.7933 27.1267 24.1666 26.6667 24.1666ZM24.1667 22.5H25.8333V20C25.8333 19.5408 25.46 19.1666 25 19.1666C24.54 19.1666 24.1667 19.5408 24.1667 20V22.5Z"
            fill="white"
          />
        </g>
      </g>
    </svg>
  );
}
