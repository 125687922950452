import React from "react";

export default function Navigation() {
  return (
    <svg
      width="50"
      height="46"
      viewBox="0 0 50 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Navigation">
        <g id="Group">
          <path
            id="Vector"
            d="M50 31.9251L45.4594 29.8295L42.7082 22.608H32.4207V19.444L32.5961 19.2686C34.6251 17.2398 35.7426 14.542 35.7426 11.6725C35.7426 5.74907 30.9234 0.929932 25 0.929932C19.0766 0.929932 14.2574 5.74897 14.2574 11.6724C14.2574 13.102 14.5349 14.4888 15.0641 15.7716H0V42.1403H6.11172C6.71641 43.8454 8.34473 45.0702 10.2545 45.0702C12.1643 45.0702 13.7925 43.8454 14.3972 42.1403H35.6027C36.2074 43.8454 37.8357 45.0702 39.7455 45.0702C41.6553 45.0702 43.2836 43.8454 43.8883 42.1403H50V31.9251ZM42.1775 29.4443H38.2805V25.5375H40.6893L42.1775 29.4443ZM25 3.85952C29.308 3.85952 32.8129 7.3644 32.8129 11.6724C32.8129 13.7593 32.0002 15.7213 30.5245 17.1969L25 22.7215L19.4754 17.1969C17.9998 15.7213 17.1871 13.7594 17.1871 11.6724C17.1871 7.3644 20.692 3.85952 25 3.85952ZM2.92969 18.7012H16.8756C17.0441 18.8955 17.2201 19.0848 17.4038 19.2685L25 26.8647L29.491 22.3736V39.2105H14.3972C13.7925 37.5054 12.1642 36.2806 10.2545 36.2806C8.34482 36.2806 6.71641 37.5054 6.11172 39.2105H2.92969V18.7012ZM10.2545 42.1404C9.44668 42.1404 8.78945 41.4832 8.78945 40.6753C8.78945 39.8674 9.44668 39.2103 10.2545 39.2103C11.0623 39.2103 11.7194 39.8674 11.7194 40.6753C11.7194 41.4832 11.0623 42.1404 10.2545 42.1404ZM39.7455 42.1404C38.9377 42.1404 38.2805 41.4832 38.2805 40.6753C38.2805 39.8674 38.9376 39.2103 39.7455 39.2103C40.5534 39.2103 41.2105 39.8674 41.2105 40.6753C41.2105 41.4832 40.5534 42.1404 39.7455 42.1404ZM47.0703 39.2105H43.8883C43.2836 37.5054 41.6553 36.2806 39.7455 36.2806C37.8357 36.2806 36.2074 37.5054 35.6027 39.2105H32.4207V25.5375H35.3508V32.374H43.9814L47.0703 33.7997V39.2105Z"
            fill="white"
          />
          <path
            id="Vector_2"
            d="M25.0002 16.5557C27.6927 16.5557 29.8832 14.3652 29.8832 11.6727C29.8832 8.9802 27.6927 6.78967 25.0002 6.78967C22.3077 6.78967 20.1172 8.9802 20.1172 11.6727C20.1172 14.3652 22.3077 16.5557 25.0002 16.5557ZM25.0002 9.71936C26.0772 9.71936 26.9535 10.5956 26.9535 11.6727C26.9535 12.7497 26.0772 13.626 25.0002 13.626C23.9231 13.626 23.0469 12.7497 23.0469 11.6727C23.0469 10.5956 23.9231 9.71936 25.0002 9.71936Z"
            fill="white"
          />
        </g>
      </g>
    </svg>
  );
}
